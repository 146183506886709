<template>
  <div class="home">
    <CommonHeader :title="title" />
    <div class="mes">
      <div v-html="message"></div>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { ref, onBeforeMount } from "vue";
import { getAgreement } from "@/api/system";
import { Toast } from "vant";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const t = ref("");
    const title = ref("");
    const message = ref("");
    const merchantid = ref("");
    onBeforeMount(() => {
      t.value = route.query.type || 1;
      merchantid.value = route.query.merchantid || "";
      console.log(t.value);
      getAgreement({ type: t.value, merchant_id:merchantid.value})
        .then((res) => {
          title.value = res.data.info.title;
          document.title = res.data.info.title;
          message.value = res.data.info.message;
        })
        .catch((message) => {
          Toast(message);
          router.back();
        });
    });

    return {
      title,
      message,
    };
  },
};
</script>

<style lang="scss" scoped>
.mes{
  padding: 30px;
  font-size: 26px;
  >>> div{
    font-size: 24px;
  }
}
</style>
